define("discourse/plugins/discourse-global-communities/discourse/initializers/topic-list", ["exports", "discourse/components/topic-list/latest-topic-list-item", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/bottom-row", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/by-line", "discourse/plugins/discourse-global-communities/discourse/components/agc/topic-list/top-row", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _latestTopicListItem, _pluginApi, _bottomRow, _byLine, _topRow, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-topic-list",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.39.0", api => {
        customizeTopicList(api);
      });
    }
  };
  function customizeTopicList(api) {
    // AGC feeds use the same layout for the cards in desktop and mobile.
    // Desktop is easier to customize to AGC needs using the topic list Glimmer API
    api.registerValueTransformer("topic-list-item-mobile-layout", () => false);
    api.registerValueTransformer("topic-list-columns", _ref => {
      let {
        value: columns
      } = _ref;
      // AGC feeds use the same layout for the categories page topics, which they use for as their home page and the
      // discovery pages (latest, top, hot, tags, etc).
      // To keep just one set of customizations for all these pages, we'll customize the layout for the topics in the
      // category page because the markup  we have in core is closer to what they need.
      // In order to reuse this in the discovery pages, we'll remove all the columns the 'topic' column and override it
      // with the custom layout by reusing the LatestTopicListItem component
      ["posters", "replies", "likes", "op-likes", "views", "activity"].forEach(columnName => columns.delete(columnName));
      columns.replace("topic", {
        item: _latestTopicListItem.default
      });
      return columns;
    });
    api.registerValueTransformer("latest-topic-list-item-class", _ref2 => {
      let {
        value: extraClasses
      } = _ref2;
      extraClasses.push("topic-list-item");
      return extraClasses;
    });
    // now we customize the topic card displayed in these pages
    api.renderInOutlet("latest-topic-list-item-topic-poster", _byLine.default);
    api.renderInOutlet("latest-topic-list-item-main-link-top-row", _topRow.default);
    api.renderInOutlet("latest-topic-list-item-main-link-bottom-row", _bottomRow.default);
    api.renderInOutlet("latest-topic-list-item-topic-stats", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{~! no whitespace ~}}
      
    */
    {
      "id": "7JwFjtB9",
      "block": "[[],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/initializers/topic-list.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  }
});