define("discourse/plugins/discourse-global-communities/discourse/services/agc-ai-summary", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "discourse/lib/get-owner", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/disclaimer-modal"], function (_exports, _tracking, _object, _runloop, _service, _getOwner, _disclaimerModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AI_SUMMARY_SHEET_OPEN_CLASS = "agc-ai-summary-sheet-wrapper-open";
  const AI_SUMMARY_SHEET_LAST_TOPIC_ID = "agc-ai-summary-sheet-last-topic-id";
  const AI_SUMMARY_DISCLAIMER_CONFIRMED = "agc-ai-summary-disclaimer-confirmed"; // if you change this, change the disclaimer modal too
  // see snapHeights below for snap points
  const DEFAULT_SHEET_HEIGHT = "50%";
  const MIN_HEIGHT_RATIO = 0.15;
  const MAX_HEIGHT_RATIO = 0.8;
  class AgcAiSummaryService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "lastTopicId", [_tracking.tracked], function () {
      return parseInt(localStorage.getItem(AI_SUMMARY_SHEET_LAST_TOPIC_ID), 10);
    }))();
    #lastTopicId = (() => (dt7948.i(this, "lastTopicId"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "open", [_tracking.tracked], function () {
      return false;
    }))();
    #open = (() => (dt7948.i(this, "open"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "bottomSheet", [_tracking.tracked]))();
    #bottomSheet = (() => (dt7948.i(this, "bottomSheet"), void 0))(); // default to open the sheet if there is a last topic id
    static #_7 = (() => dt7948.g(this.prototype, "sheetHeight", [_tracking.tracked], function () {
      return DEFAULT_SHEET_HEIGHT;
    }))();
    #sheetHeight = (() => (dt7948.i(this, "sheetHeight"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "bottomSheetLinkClicked", [_tracking.tracked], function () {
      return false;
    }))();
    #bottomSheetLinkClicked = (() => (dt7948.i(this, "bottomSheetLinkClicked"), void 0))();
    _analyticsTimer;
    _accruedViewTime = 0;
    constructor() {
      super(...arguments);
      (0, _runloop.scheduleOnce)("afterRender", this.attemptOpenOnFirstLoad);
      document.addEventListener("visibilitychange", this.onVisibilityChange);
      window.addEventListener("beforeunload", () => {
        if (this.open) {
          this.#storeViewTimeAnalytics(this.lastTopicId);
        }
      });
    }
    willDestroy() {
      document.removeEventListener("visibilitychange", this.onVisibilityChange);
    }
    attemptOpenOnFirstLoad() {
      if (this.lastTopicId && this.topicController.model?.id === this.lastTopicId) {
        this.openAiSummarySheet(this.lastTopicId);
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "attemptOpenOnFirstLoad", [_object.action]))();
    get topicController() {
      return (0, _getOwner.getOwnerWithFallback)(this).lookup("controller:topic");
    }
    onVisibilityChange() {
      if (!this.open) {
        return;
      }
      if (document.hidden) {
        this.pauseAnalyticsTimer();
      } else {
        this.startAnalyticsTimer();
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "onVisibilityChange", [_object.action]))();
    startAnalyticsTimer() {
      this.analyticsTimer = new Date();
    }
    static #_11 = (() => dt7948.n(this.prototype, "startAnalyticsTimer", [_object.action]))();
    pauseAnalyticsTimer() {
      this._accruedViewTime = this._accruedViewTime + (new Date() - this.analyticsTimer);
      this.analyticsTimer = null;
    }
    static #_12 = (() => dt7948.n(this.prototype, "pauseAnalyticsTimer", [_object.action]))();
    setBottomSheetHeight(el) {
      this.bottomSheet = el;
      this.bottomSheet.style.height = this.sheetHeight;
    }
    static #_13 = (() => dt7948.n(this.prototype, "setBottomSheetHeight", [_object.action]))();
    openAiSummarySheet(topicId) {
      // Show disclaimer modal on first open
      if (localStorage.getItem(AI_SUMMARY_DISCLAIMER_CONFIRMED) !== "true") {
        this.modal.show(_disclaimerModal.default);
      }
      this.open = true;
      this.sheetHeight = DEFAULT_SHEET_HEIGHT; // reset the height every time
      this.lastTopicId = topicId;
      localStorage.setItem(AI_SUMMARY_SHEET_LAST_TOPIC_ID, topicId);
      document.body.classList.add(AI_SUMMARY_SHEET_OPEN_CLASS);
      this.startAnalyticsTimer();
    }
    static #_14 = (() => dt7948.n(this.prototype, "openAiSummarySheet", [_object.action]))();
    closeSheet(e) {
      e?.preventDefault();
      this.#storeViewTimeAnalytics(this.lastTopicId);
      this.open = false;
      this.lastTopicId = null;
      localStorage.setItem(AI_SUMMARY_SHEET_LAST_TOPIC_ID, null);
      document.body.classList.remove(AI_SUMMARY_SHEET_OPEN_CLASS);
      if (this.site.mobileView) {
        this.bottomSheet = null;
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "closeSheet", [_object.action]))();
    dragMove(e) {
      // Get the current window height
      const windowHeight = window.innerHeight;
      const minHeight = windowHeight * MIN_HEIGHT_RATIO;
      const maxHeight = windowHeight * MAX_HEIGHT_RATIO;

      // Get clientY from mouse or touch event
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      // Calculate new height (from bottom of screen to touch point)
      const newHeight = windowHeight - clientY;

      // Constrain height between min and max values
      const constrainedHeight = Math.max(minHeight, Math.min(maxHeight, newHeight));

      // Apply the new height
      this.bottomSheet.style.height = `${constrainedHeight}px`;
    }
    static #_16 = (() => dt7948.n(this.prototype, "dragMove", [_object.action]))();
    didEndDrag(e) {
      // If the user clicked on the close button, close the sheet and don't fire drag logic
      if (e.target.closest(".close-button")) {
        this.closeSheet(e);
        return;
      }

      // Get current height
      const currentHeight = parseFloat(this.bottomSheet.style.height);
      const windowHeight = window.innerHeight;

      // Get the header height to use as minimum snap point
      const headerElement = this.bottomSheet.querySelector(".bottom-sheet__header");
      const headerHeight = headerElement ? headerElement.offsetHeight : windowHeight * MIN_HEIGHT_RATIO;

      // Get header offset so that on 100% height, we don't cover the header
      const headerOffset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--header-offset")) || 0;

      // Calculate snap heights with header height as minimum
      const snapHeights = [headerHeight,
      // Minimum snap is header height
      windowHeight * 0.5,
      // Middle snap at 50%
      windowHeight - headerOffset // Full height snap
      ];

      // Find the closest snap point
      let closestSnapHeight = snapHeights[0];
      let minDistance = Math.abs(currentHeight - snapHeights[0]);
      for (let i = 1; i < snapHeights.length; i++) {
        const distance = Math.abs(currentHeight - snapHeights[i]);
        if (distance < minDistance) {
          minDistance = distance;
          closestSnapHeight = snapHeights[i];
        }
      }

      // Apply the snap height
      this.bottomSheet.style.height = `${closestSnapHeight}px`;
      this.sheetHeight = this.bottomSheet.style.height;
    }
    static #_17 = (() => dt7948.n(this.prototype, "didEndDrag", [_object.action]))();
    #storeViewTimeAnalytics(topicId) {
      this.pauseAnalyticsTimer();
      this.appEvents.trigger("ai_summary:open_time", {
        ms: this._accruedViewTime,
        topic_id: topicId
      });
      this._accruedViewTime = 0;
    }
    collapseOrExpandSheet(e) {
      if (!this.open) {
        return;
      }

      // Set the height to half of the window if the clicked element is an <a> tag and if it is not disclaimer
      if (e.target.tagName.toLowerCase() === "a") {
        // This is to prevent the sheet from collapsing when clicking on links inside the sheet
        this.bottomSheetLinkClicked = true;

        // const windowHeight = window.innerHeight;
        this.bottomSheet.style.height = "50%";
        this.sheetHeight = this.bottomSheet.style.height;
      }
    }
    static #_18 = (() => dt7948.n(this.prototype, "collapseOrExpandSheet", [_object.action]))();
    expandSheet() {
      if (!this.open) {
        return;
      }
      const windowHeight = window.innerHeight;
      const headerOffset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--header-offset")) || 0;
      this.bottomSheet.style.height = `${windowHeight - headerOffset}px`;
      this.sheetHeight = this.bottomSheet.style.height;
    }
    static #_19 = (() => dt7948.n(this.prototype, "expandSheet", [_object.action]))();
    minimizeSheet() {
      if (!this.open) {
        return;
      }

      // Get the header height to use as minimum snap point
      const headerElement = this.bottomSheet.querySelector(".bottom-sheet__header");
      const headerHeight = headerElement ? headerElement.offsetHeight : window.innerHeight * MIN_HEIGHT_RATIO;
      this.bottomSheet.style.height = `${headerHeight}px`;
      this.sheetHeight = this.bottomSheet.style.height;
    }
    static #_20 = (() => dt7948.n(this.prototype, "minimizeSheet", [_object.action]))();
  }
  _exports.default = AgcAiSummaryService;
});