define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/new-comment", ["exports", "@ember/modifier", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _modifier, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NewComment = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @bufferedSummary}}
      <div class="new-comment">
        <span>{{i18n "agc.ai.new_comment"}}</span>
        <span class="new-comment__refresh">
          <a {{on "click" @fetchNewSummary}} role="button">
            {{i18n "agc.ai.refresh"}}
          </a>
        </span>
      </div>
    {{/if}}
  
  */
  {
    "id": "VCZE47is",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"new-comment\"],[12],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,0],[\"agc.ai.new_comment\"],null]],[13],[1,\"\\n      \"],[10,1],[14,0,\"new-comment__refresh\"],[12],[1,\"\\n        \"],[11,3],[24,\"role\",\"button\"],[4,[32,1],[\"click\",[30,2]],null],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"agc.ai.refresh\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@bufferedSummary\",\"@fetchNewSummary\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/new-comment.js",
    "scope": () => [_discourseI18n.i18n, _modifier.on],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "new-comment:NewComment"));
  var _default = _exports.default = NewComment;
});