define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sheet-wrapper", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/decorators", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/bottom-sheet", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sidebar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _controller, _object, _service, _decorators, _bottomSheet, _sidebar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SheetWrapper extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "agcAiSummary", [_service.service]))();
    #agcAiSummary = (() => (dt7948.i(this, "agcAiSummary"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "topicController", [_controller.inject]))();
    #topicController = (() => (dt7948.i(this, "topicController"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "bufferedSummary", [_tracking.tracked], function () {
      return null;
    }))();
    #bufferedSummary = (() => (dt7948.i(this, "bufferedSummary"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "summary", [_tracking.tracked], function () {
      return this.args.topic.latest_topic_summary;
    }))();
    #summary = (() => (dt7948.i(this, "summary"), void 0))();
    constructor() {
      super(...arguments);
      this.messageBus.subscribe("/ai-summary-new-comments", this.onNewComment);
    }
    willDestroy() {
      super.willDestroy();
      this.agcAiSummary.closeSheet();
      this.messageBus.unsubscribe("/ai-summary-new-comments");
    }
    get sheetComponent() {
      return this.site.desktopView ? _sidebar.default : _bottomSheet.default;
    }
    get summaryNotAvailable() {
      return this.args.topic.agc_hide_ai_topic_summary;
    }
    fetchNewSummary() {
      this.summary = this.bufferedSummary;
      this.bufferedSummary = null;
    }
    static #_8 = (() => dt7948.n(this.prototype, "fetchNewSummary", [_object.action]))();
    onNewComment(data) {
      if (data.summary && this.topicController?.model?.id === data.topicId) {
        this.bufferedSummary = data.summary;
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "onNewComment", [_decorators.bind]))();
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.agcAiSummary.open}}
          <this.sheetComponent
            @summary={{this.summary}}
            @summaryNotAvailable={{this.summaryNotAvailable}}
            @fetchNewSummary={{this.fetchNewSummary}}
            @bufferedSummary={{this.bufferedSummary}}
          />
        {{/if}}
      
    */
    {
      "id": "HFmKXRIJ",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"agcAiSummary\",\"open\"]],[[[1,\"      \"],[8,[30,0,[\"sheetComponent\"]],null,[[\"@summary\",\"@summaryNotAvailable\",\"@fetchNewSummary\",\"@bufferedSummary\"],[[30,0,[\"summary\"]],[30,0,[\"summaryNotAvailable\"]],[30,0,[\"fetchNewSummary\"]],[30,0,[\"bufferedSummary\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/sheet-wrapper.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SheetWrapper;
});