define("discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/bottom-sheet", ["exports", "@glimmer/component", "@ember/modifier", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-button", "discourse/lib/decorators", "discourse/modifiers/draggable", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/content", "discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/new-comment", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _didInsert, _service, _dButton, _decorators, _draggable, _discourseI18n, _content, _newComment, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BottomSheet extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcAiSummary", [_service.service]))();
    #agcAiSummary = (() => (dt7948.i(this, "agcAiSummary"), void 0))();
    constructor() {
      super(...arguments);
      window.addEventListener("scroll", this.windowScrollHandler, {
        passive: false
      });
    }
    willDestroy() {
      super.willDestroy(...arguments);
      window.removeEventListener("scroll", this.windowScrollHandler);
    }
    windowScrollHandler() {
      if (this.agcAiSummary.bottomSheetLinkClicked) {
        this.agcAiSummary.bottomSheetLinkClicked = false;
        return;
      }
      if (this.agcAiSummary.open) {
        this.agcAiSummary.minimizeSheet();
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "windowScrollHandler", [_decorators.bind]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="bottom-sheet"
          id="agc-ai-summary-bottom-sheet"
          {{didInsert this.agcAiSummary.setBottomSheetHeight}}
        >
          <div
            class="bottom-sheet__header"
            {{draggable
              didStartDrag=this.agcAiSummary.didStartDrag
              didEndDrag=this.agcAiSummary.didEndDrag
              dragMove=this.agcAiSummary.dragMove
            }}
          >
            <div class="bottom-sheet__header-title">
              <h3>{{i18n "agc.ai.post_summary"}}</h3>
              <DButton
                @icon="xmark"
                @action={{this.agcAiSummary.closeSheet}}
                class="no-text btn-icon btn-transparent close-button"
              />
            </div>
            <NewComment
              @fetchNewSummary={{@fetchNewSummary}}
              @bufferedSummary={{@bufferedSummary}}
            />
          </div>
          <div
            class="bottom-sheet__content"
            role="button"
            {{on "click" this.agcAiSummary.collapseOrExpandSheet}}
            {{on "scroll" this.agcAiSummary.expandSheet}}
          >
            <Content
              @summary={{@summary}}
              @summaryNotAvailable={{@summaryNotAvailable}}
            />
          </div>
        </div>
      
    */
    {
      "id": "6ANCIbyN",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"bottom-sheet\"],[24,1,\"agc-ai-summary-bottom-sheet\"],[4,[32,0],[[30,0,[\"agcAiSummary\",\"setBottomSheetHeight\"]]],null],[12],[1,\"\\n      \"],[11,0],[24,0,\"bottom-sheet__header\"],[4,[32,1],null,[[\"didStartDrag\",\"didEndDrag\",\"dragMove\"],[[30,0,[\"agcAiSummary\",\"didStartDrag\"]],[30,0,[\"agcAiSummary\",\"didEndDrag\"]],[30,0,[\"agcAiSummary\",\"dragMove\"]]]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"bottom-sheet__header-title\"],[12],[1,\"\\n          \"],[10,\"h3\"],[12],[1,[28,[32,2],[\"agc.ai.post_summary\"],null]],[13],[1,\"\\n          \"],[8,[32,3],[[24,0,\"no-text btn-icon btn-transparent close-button\"]],[[\"@icon\",\"@action\"],[\"xmark\",[30,0,[\"agcAiSummary\",\"closeSheet\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,4],null,[[\"@fetchNewSummary\",\"@bufferedSummary\"],[[30,1],[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[11,0],[24,0,\"bottom-sheet__content\"],[24,\"role\",\"button\"],[4,[32,5],[\"click\",[30,0,[\"agcAiSummary\",\"collapseOrExpandSheet\"]]],null],[4,[32,5],[\"scroll\",[30,0,[\"agcAiSummary\",\"expandSheet\"]]],null],[12],[1,\"\\n        \"],[8,[32,6],null,[[\"@summary\",\"@summaryNotAvailable\"],[[30,3],[30,4]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@fetchNewSummary\",\"@bufferedSummary\",\"@summary\",\"@summaryNotAvailable\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/ai-summary/bottom-sheet.js",
      "scope": () => [_didInsert.default, _draggable.default, _discourseI18n.i18n, _dButton.default, _newComment.default, _modifier.on, _content.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BottomSheet;
});