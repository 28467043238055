define("discourse/plugins/discourse-global-communities/discourse/components/sidebar-custom-widget", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/cook-text", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _service, _cookText, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SidebarCustomWidget extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    sidebarCustomWidgetClicked() {
      this.appEvents.trigger("agc-custom:homepage_sidebar_item_click", {
        item: this.args.widgetData.title
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "sidebarCustomWidgetClicked", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="homepage-hero-cell sidebar-custom-widget"
          role="button"
          {{on "click" this.sidebarCustomWidgetClicked}}
        >
          <h3 class="cell-title">{{@widgetData.title}}</h3>
    
          <div class="cell-content">
            <CookText @rawText={{@widgetData.content}} />
          </div>
        </div>
      
    */
    {
      "id": "b3YV4iD2",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"homepage-hero-cell sidebar-custom-widget\"],[24,\"role\",\"button\"],[4,[32,0],[\"click\",[30,0,[\"sidebarCustomWidgetClicked\"]]],null],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"cell-content\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@rawText\"],[[30,1,[\"content\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@widgetData\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/sidebar-custom-widget.js",
      "scope": () => [_modifier.on, _cookText.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SidebarCustomWidget;
});